@each $size in $sizes {
    @include build('hidden', $size) {
        display: none;
    }

    @include build('block', $size) {
        display: block;
    }

    @include build('inline-block', $size) {
        display: inline-block;
    }

    @include build('inline', $size) {
        display: inline;
    }

    // Flex
    @include build('flex', $size) {
        display: flex;
    }
    @include build('inline-flex', $size) {
        display: inline-flex;
    }
    @include build('flex-row', $size) {
        flex-direction: row;
    }
    @include build('flex-row-reverse', $size) {
        flex-direction: row-reverse;
    }
    @include build('flex-column', $size) {
        flex-direction: column;
    }
    @include build('flex-column-reverse', $size) {
        flex-direction: column-reverse;
    }
    @include build('flex-wrap', $size) {
        flex-wrap: wrap;
    }
    @include build('flex-nowrap', $size) {
        flex-wrap: nowrap;
    }
    @include build('flex-wrap-reverse', $size) {
        flex-wrap: wrap-reverse;
    }
    @include build('content-start', $size) {
        justify-content: flex-start;
    }
    @include build('content-end', $size) {
        justify-content: flex-end;
    }
    @include build('content-center', $size) {
        justify-content: center;
    }
    @include build('content-between', $size) {
        justify-content: space-between;
    }
    @include build('content-around', $size) {
        justify-content: space-around;
    }
    @include build('content-evenly', $size) {
        justify-content: space-evenly;
    }
    @include build('items-start', $size) {
        align-items: flex-start;
    }
    @include build('items-end', $size) {
        align-items: flex-end;
    }
    @include build('items-center', $size) {
        align-items: center;
    }
    @include build('items-baseline', $size) {
        align-items: baseline;
    }
    @include build('items-stretch', $size) {
        align-items: stretch;
    }
}
