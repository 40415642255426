$margin-vertical-small: 4px;
$margin-vertical: 8px;
$margin-vertical-large: 12px;
$margin-vertical-x-large: 16px;

$margin-horizontal-small: 8px;
$margin-horizontal: 12px;
$margin-horizontal-large: 16px;

@each $size in $sizes {
    @include build('margin', $size) {
        margin: $margin-vertical $margin-horizontal;
    }

    @include build('margin-small', $size) {
        margin: $margin-vertical-small $margin-horizontal-small;
    }

    @include build('margin-large', $size) {
        margin: $margin-vertical-large $margin-horizontal-large;
    }

    @include build('margin-none', $size) {
        margin: 0px;
    }

    @include build('margin-v', $size) {
        margin-top: $margin-vertical;
        margin-bottom: $margin-vertical;
    }

    @include build('margin-v-small', $size) {
        margin-top: $margin-vertical-small;
        margin-bottom: $margin-vertical-small;
    }

    @include build('margin-v-large', $size) {
        margin-top: $margin-vertical-large;
        margin-bottom: $margin-vertical-large;
    }

    @include build('margin-v-x-large', $size) {
        margin-top: $margin-vertical-x-large;
        margin-bottom: $margin-vertical-x-large;
    }

    @include build('margin-v-none', $size) {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    @include build('margin-h', $size) {
        margin-left: $margin-horizontal;
        margin-right: $margin-horizontal;
    }

    @include build('margin-h-small', $size) {
        margin-left: $margin-horizontal-small;
        margin-right: $margin-horizontal-small;
    }

    @include build('margin-h-large', $size) {
        margin-left: $margin-horizontal-large;
        margin-right: $margin-horizontal-large;
    }

    @include build('margin-h-none', $size) {
        margin-left: 0px;
        margin-right: 0px;
    }

    @include build('margin-top', $size) {
        margin-top: $margin-vertical;
    }

    @include build('margin-top-small', $size) {
        margin-top: $margin-vertical-small;
    }

    @include build('margin-top-large', $size) {
        margin-top: $margin-vertical-large;
    }

    @include build('margin-top-x-large', $size) {
        margin-top: $margin-vertical-x-large;
    }

    @include build('margin-top-none', $size) {
        margin-top: 0px;
    }

    @include build('margin-bottom', $size) {
        margin-bottom: $margin-vertical;
    }

    @include build('margin-bottom-small', $size) {
        margin-bottom: $margin-vertical-small;
    }

    @include build('margin-bottom-large', $size) {
        margin-bottom: $margin-vertical-large;
    }

    @include build('margin-bottom-x-large', $size) {
        margin-bottom: $margin-vertical-x-large;
    }

    @include build('margin-bottom-none', $size) {
        margin-bottom: 0px;
    }

    @include build('margin-left', $size) {
        margin-left: $margin-horizontal;
    }

    @include build('margin-left-small', $size) {
        margin-left: $margin-horizontal-small;
    }

    @include build('margin-left-large', $size) {
        margin-left: $margin-horizontal-large;
    }

    @include build('margin-left-none', $size) {
        margin-left: 0px;
    }

    @include build('margin-right', $size) {
        margin-right: $margin-horizontal;
    }

    @include build('margin-right-small', $size) {
        margin-right: $margin-horizontal-small;
    }

    @include build('margin-right-large', $size) {
        margin-right: $margin-horizontal-large;
    }

    @include build('margin-right-none', $size) {
        margin-right: 0px;
    }
}
