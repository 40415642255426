@import 'scss/mixins';

$default-border-color: $color--text-line;
$color-map: (
    'primary': $color--branding-blue,
    'secondary': $color--text-2,
    'light': $color--white-transparent-white,
    'light-10': $color--white-transparent-white-10,
    'cyan': $color--system-cyan,
    'purple': $color--system-purple,
    'yellow': $color--system-yellow,
    'link': $color--branding-hyper-link,
);

@each $size in $sizes {
    @include build('border', $size) {
        border: 1px solid $default-border-color;
    }

    @include build('border-top', $size) {
        border-top: 1px solid $default-border-color;
    }

    @include build('border-bottom', $size) {
        border-bottom: 1px solid $default-border-color;
    }

    @include build('border-left', $size) {
        border-left: 1px solid $default-border-color;
    }

    @include build('border-right', $size) {
        border-right: 1px solid $default-border-color;
    }

    @each $key, $value in $color-map {
        @include build('border-#{$key}', $size) {
            border: 1px solid $value;
        }

        @include build('border-top-#{$key}', $size) {
            border-top: 1px solid $value;
        }

        @include build('border-bottom-#{$key}', $size) {
            border-bottom: 1px solid $value;
        }

        @include build('border-left-#{$key}', $size) {
            border-left: 1px solid $value;
        }

        @include build('border-right-#{$key}', $size) {
            border-right: 1px solid $value;
        }
    }

    @include build('border-none', $size) {
        border: none;
    }

    @include build('border-left-none', $size) {
        border-left: none;
    }

    @include build('border-right-none', $size) {
        border-right: none;
    }

    @include build('border-top-none', $size) {
        border-top: none;
    }

    @include build('border-bottom-none', $size) {
        border-bottom: none;
    }
}
