$colors: (
    'light': $color--white-transparent-white,
    'light-50': $color--white-transparent-white-50,
    'light-80': $color--white-transparent-white-80,
    'cyan': $color--system-cyan,
    'default': $color--text-1,
    'text-3': $color--text-3,
);

@mixin generateColor($classNamePrefix, $attributeName, $size) {
    @each $key, $value in $colors {
        @include build('#{$classNamePrefix}-#{$key}', $size) {
            #{$attributeName}: $value;
        }
    }
}

@each $size in $sizes {
    @include generateColor('color', 'color', $size);
}
