$padding-vertical-small: 4px;
$padding-vertical: 8px;
$padding-vertical-large: 12px;
$padding-vertical-x-large: 16px;

$padding-horizontal-small: 8px;
$padding-horizontal: 12px;
$padding-horizontal-large: 16px;

@each $size in $sizes {
    @include build('pd', $size) {
        padding: $padding-vertical $padding-horizontal;
    }

    @include build('pd-small', $size) {
        padding: $padding-vertical-small $padding-horizontal-small;
    }

    @include build('pd-large', $size) {
        padding: $padding-vertical-large $padding-horizontal-large;
    }

    @include build('pd-none', $size) {
        padding: 0px;
    }

    @include build('pd-v', $size) {
        padding-top: $padding-vertical;
        padding-bottom: $padding-vertical;
    }

    @include build('pd-v-small', $size) {
        padding-top: $padding-vertical-small;
        padding-bottom: $padding-vertical-small;
    }

    @include build('pd-v-large', $size) {
        padding-top: $padding-vertical-large;
        padding-bottom: $padding-vertical-large;
    }

    @include build('pd-v-x-large', $size) {
        padding-top: $padding-vertical-x-large;
        padding-bottom: $padding-vertical-x-large;
    }

    @include build('pd-v-none', $size) {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    @include build('pd-h', $size) {
        padding-left: $padding-horizontal;
        padding-right: $padding-horizontal;
    }

    @include build('pd-h-small', $size) {
        padding-left: $padding-horizontal-small;
        padding-right: $padding-horizontal-small;
    }

    @include build('pd-h-large', $size) {
        padding-left: $padding-horizontal-large;
        padding-right: $padding-horizontal-large;
    }

    @include build('pd-h-none', $size) {
        padding-left: 0px;
        padding-right: 0px;
    }

    @include build('pd-top', $size) {
        padding-top: $padding-vertical;
    }

    @include build('pd-top-small', $size) {
        padding-top: $padding-vertical-small;
    }

    @include build('pd-top-large', $size) {
        padding-top: $padding-vertical-large;
    }

    @include build('pd-top-x-large', $size) {
        padding-top: $padding-vertical-x-large;
    }

    @include build('pd-top-none', $size) {
        padding-top: 0px;
    }

    @include build('pd-bottom', $size) {
        padding-bottom: $padding-vertical;
    }

    @include build('pd-bottom-small', $size) {
        padding-bottom: $padding-vertical-small;
    }

    @include build('pd-bottom-large', $size) {
        padding-bottom: $padding-vertical-large;
    }

    @include build('pd-bottom-x-large', $size) {
        padding-bottom: $padding-vertical-x-large;
    }

    @include build('pd-bottom-none', $size) {
        padding-bottom: 0px;
    }

    @include build('pd-left', $size) {
        padding-left: $padding-horizontal;
    }

    @include build('pd-left-small', $size) {
        padding-left: $padding-horizontal-small;
    }

    @include build('pd-left-large', $size) {
        padding-left: $padding-horizontal-large;
    }

    @include build('pd-left-none', $size) {
        padding-left: 0px;
    }

    @include build('pd-right', $size) {
        padding-right: $padding-horizontal;
    }

    @include build('pd-right-small', $size) {
        padding-right: $padding-horizontal-small;
    }

    @include build('pd-right-large', $size) {
        padding-right: $padding-horizontal-large;
    }

    @include build('pd-right-none', $size) {
        padding-right: 0px;
    }
}
