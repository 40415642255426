$COL_WIDTH: 44px;
$GAP_WIDTH: 12px;

@each $size in $sizes {
    @for $i from 1 through 24 {
        @include build('max-width-#{$i}', $size) {
            max-width: $COL_WIDTH * $i + $GAP_WIDTH * ($i - 1);
            margin-left: auto;
            margin-right: auto;
        }
    }

    @for $i from 1 through 24 {
        @include build('max-width-1\\\/#{$i}', $size) {
            max-width: calc(100% / #{$i});
        }
    }
}
