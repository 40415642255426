@mixin font-14 {
    font-size: 14px;
    line-height: 20px;
}

@mixin font-16 {
    font-size: 16px;
    line-height: 24px;
}

@mixin font-18 {
    font-size: 18px;
    line-height: 24px;
}

@mixin font-12 {
    font-size: 12px;
    line-height: 16px;
}

@mixin font-20 {
    font-size: 20px;
    line-height: 28px;
}

@mixin font-24 {
    font-size: 24px;
    line-height: 32px;
}

@mixin font-32 {
    font-size: 32px;
    line-height: 40px;
}

@mixin font-40 {
    font-size: 40px;
    line-height: 48px;
}

@mixin overflow-long-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

$shadow-inset-bottom: inset 0px -1px 0px rgba(
        $color: $color--black,
        $alpha: 0.1,
    );

$common-transition: all 0.3s ease-in-out;
@mixin common-transition($props: all) {
    transition: #{$props} 0.3s ease-in-out;
}

.skeleton-box {
    display: block;
    position: relative;
    overflow: hidden;
    background-color: #f1f1f1;

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
            90deg,
            rgba(#fff, 0) 0,
            rgba(#fff, 0.2) 20%,
            rgba(#fff, 0.5) 60%,
            rgba(#fff, 0)
        );
        animation: shimmer 2s infinite;
        content: '';
    }
}
