@use 'sass:list';

$sizes: '', 'mobile', 'tablet';

@mixin build($className, $size) {
    @if ($size == 'mobile') {
        @media only screen and (max-width: 768px) {
            .mobile\:#{$className} {
                @content;
            }
        }
    } @else if ($size == 'tablet') {
        @media only screen and (max-width: 992px) {
            .tablet\:#{$className} {
                @content;
            }
        }
    } @else {
        .#{$className} {
            @content;
        }
        .hover\:#{$className}:hover {
            @content;
        }
        .active\:#{$className}:active {
            @content;
        }
    }
}
