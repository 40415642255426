$background-colors: (
    'bg-blue': $color--bg-blue,
    'bg-hover': $color--bg-hover,
    'black-8': $color--black-transparent-black-8,
    'cyan': $color--system-cyan,
    'dark-primary': $color--branding-dark-blue,
    'light': $color--white-transparent-white,
    'primary': $color--branding-light-blue,
    'purple': $color--system-purple,
    'secondary': $color--text-2,
    'text-3': $color--text-3,
    'yellow': $color--system-yellow,
);

@mixin generateColor($classNamePrefix, $attributeName, $size) {
    @each $key, $value in $background-colors {
        @include build('#{$classNamePrefix}-#{$key}', $size) {
            #{$attributeName}: $value;
        }
    }
}

@each $size in $sizes {
    @include generateColor('bg', 'background-color', $size);
}
