@import 'scss/global.scss';

body {
    margin: 0px;
    font-family: $font-inter;
}

[hidden] {
    display: none !important;
}
